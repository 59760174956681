<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <form novalidate v-on:submit.prevent>
          <div class="row">
            <div class="col-sm-12">
              <h2 id="title-of-block-check">Reset Password</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="alert alert-danger" v-if="anyErrors" role="alert">
                <span v-for="error in errors" v-bind:key="error">{{error}}</span>
              </div>
              <change-password-inputs
                ref="password"
                v-model="passwords"
                v-bind:user-id="query.userId"
              ></change-password-inputs>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button
                class="btn btn-primary"
                @click="submit"
                :disabled="!passwords.valid"
                type="submit"
              >Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import changePasswordInputs from '@/Components/UserSettings/ChangePassword/ChangePasswordInputs'

export default {
  name: 'resetPassword',
  props: ['query'],
  components: {
    'change-password-inputs': changePasswordInputs
  },
  computed: {
    anyErrors () {
      // some with this will return true if anything exists in JS
      return this.errors.some(x => x)
    }
  },
  data () {
    return {
      passwords: {
        password: '',
        confirmPassword: '',
        valid: false
      },
      errors: []
    }
  },
  methods: {
    async submit () {
      this.$refs.password.$v.$touch()

      if (!this.$refs.password.$v.$invalid) {
        var tokenResponse = await axios.get('Home/GetToken')
        axios.defaults.headers.common['X-XSRF-TOKEN'] = tokenResponse.data.token

        var response = await axios.post('Account/ResetPassword', { UserId: this.query.userId, Code: this.query.code, Password: this.passwords.password, ConfirmPassword: this.passwords.confirmPassword }, { showload: true })

        if (response.data.success) {
          await this.$snapbar.s('Password Reset - Please login again with your new password')
          this.$router.replace({ name: 'Login', query: { returnUrl: this.query.returnUrl }, params: { username: response.data.username, password: this.passwords.password } })
        } else {
          this.errors = response.data.errors
        }
      }
    }
  }
}
</script>
